export default {
	adminMenu_general: "Generelt",
	adminMenu_professionalAutosignatures: "Autosignaturer",
	adminMenu_patientStates: "Brukerstatus",
	adminMenu_unprocessedPatients: "Brukere uten f.nr. oppdatering",
	adminMenu_letterTemplates: "Brevmaler",
	adminMenu_pathwayTypes: "Sakstyper",
	adminMenu_financeConfiguration: "Konfigurasjon",
	adminMenu_financialAccounts: "Kontonumre",
	adminMenu_kleNumbers: "KLE-numre",
	adminMenu_subjectTemplates: "Korrespondanseemne",
	adminMenu_grant_offer_portal_suppliers: "Utf\u00F8rende enheter fra Tilbudsportalen",
	adminMenu_homeAdjustment: "Boligtilpasninger",
	adminMenu_suppliers: "Utf\u00F8rende enheter",
	adminMenu_logViewer: "Loggviser",
	adminMenu_locations: "Lokaler",
	adminMenu_mobileProfile: "Mobilkonfigurasjon",
	adminMenu_organizations: "Organisasjoner, ansatte og sikkerhet",
	adminMenu_assignments: "Oppgaver",
	adminMenu_assignment_pause_reasons: "Oppgaver - \u00C5rsaker til pause",
	adminMenu_paragraphs: "Paragrafer",
	adminMenu_physicalResources: "Ressurser",
	adminMenu_physicalResourceTypes: "Ressurstyper",
	adminMenu_citizenCompensationRate: "Satser",
	adminMenu_professionalLocks: "Se l\u00E5ste ansatte",
	adminMenu_formDefinitions: "Skjemaer",
	adminMenu_formDefinitions_new: "Skjemaer ny",
	adminMenu_professionalJobs: "Stillingstitler",
	adminMenu_sites: "Steder",
	adminMenu_hospitalStay: "Sykehusopphold",
	adminMenu_tags: "Merker",
	adminMenu_shifts: "Vakter",
	adminMenu_programPathways: "Saker",
	adminMenu_catalogGrants: "Tjenestekatalog",
	adminMenu_finance: "\u00d8konomi",
	adminMenu_payoutRecipients: "Utbetalingsmottakere",
	adminMenu_payoutFinancialAccounts: "Kontonumre til utbetaling",
	adminMenu_calendar: "Kalender",
	adminMenu_eventTypes: "Avtaletyper",
	adminMenu_plannedGrantEventTypes: "Bes\u00F8kstyper",
	adminMenu_groupEventSubTypes: "Kalenderavtaletyper",
	adminMenu_groupEventRegistrationStatus: "Kalenderavtalestatus",
	adminMenu_groupRegisteredGrantStatuses: "Levert tjenestestatus (Avtaler)",
	adminMenu_registeredGrantStatuses: "Levert tjenestestatus (Bes\u00F8k)",
	adminMenu_groupPlannedGrantStatuses: "Planlagt tjenestestatus (Avtaler)",
	adminMenu_plannedGrantStatuses: "Planlagt tjenestestatus (Bes\u00F8k)",
	adminMenu_resourcePlannedGrantStatuses: "Planlagt tjenestestatus (Ressurser)",
	adminMenu_resourceEventTypes: "Ansattavtaletype",
	adminMenu_resourceEventStatuses: "Ansattavtalestatus",
	adminMenu_registrationStatus: "Registreringsstatus",
	adminMenu_calendarRoutes: "Ruter",
	adminMenu_contacts: "Eksterne kontakter",
	adminMenu_organizationContacts: "Kontaktorganisasjoner",
	adminMenu_organizationContactTypes: "Kontaktorganisasjonstyper",
	adminMenu_professionalContacts: "Kontaktpersoner",
	adminMenu_professionalContactTypes: "Kontaktpersontyper",
	adminMenu_relativeContactTypes: "P\u00E5r\u00F8rendetyper",
	adminMenu_medicin: "Medisin",
	adminMenu_internalDrugs: "Internt opprettede preparater",
	adminMenu_inventoryManagement: "Lager",
	adminMenu_reports: "Rapporter",
	adminMenu_measurements: "M\u00E5linger",
	adminMenu_hclProducts: "Hjelpemidler",
	adminMenu_hclHmiCatalogues: "Hjelpemiddelkataloger",
	adminMenu_hclDepots: "Depoter",
	adminMenu_hclSuppliers: "Forhandlere",
	adminMenu_hclProductSuppliers: "Produktleverand\u00F8rer",
	adminMenu_hclLabelDesign: "Etikettdesign",
	adminMenu_hclCars: "Biler",
	adminMenu_hclDrivingZones: "Kj\u00F8ringssoner",
	adminMenu_hclDeliveryTypes: "Kj\u00F8ringstyper",
	adminMenu_hclMinimumInventory: "Minimumsbeholdning",
	adminMenu_hclOpslag: "Oppslag",
	adminMenu_hclAutomation: "Automatisering",
	adminMenu_hclProductItemsOwners: "Eier",
	adminMenu_patientCategory: "Brukerkategorier",
	adminMenu_financeContactCreditors: "Kreditorer",
	adminMenu_reminders: "P\u00e5minnelser",
	adminMenu_tabletAppConfiguration: "Nettbrett app konfigurasjon",
	adminMenu_hclStockPlacements: "Lagerplassering",

	mainMenu_overview: "Oversikt",
	mainMenu_citizens: "Brukere",
	mainMenu_activityList: "Aktivitetsliste",
	mainMenu_calendar: "Kalender",
	mainMenu_data: "Data",
	mainMenu_advise: "E-melding",
	mainMenu_advise_inbox: "E-melding innboks",
	mainMenu_advise_draft: "E-melding utkast",
	mainMenu_advise_outbox: "E-melding sendt",
	mainMenu_advise_archive: "E-melding arkiv",
	mainMenu_waitingList: "Venteliste",
	mainMenu_distributionLists: "Fordelingslister",
	mainMenu_finance: "\u00d8konomi",
	mainMenu_finance_billingMunicipalList: "Takstoppkreving",
	mainMenu_finance_billingMunicipalPlanning: "Planlagte takstoppkrevinger",
	mainMenu_finance_billingPatientList: "Pasientoppkreving",
	mainMenu_finance_billingPatientPlanning: "Planlagte pasientoppkrevinger",
	mainMenu_finance_fkInvoiceActiveDocuments: "Fakturaer til behandling",
	mainMenu_finance_fkInvoiceAllDocuments: "Fakturas\u00f8k",
	mainMenu_finance_payoutTransactions: "Utbetalinger",
	mainMenu_finance_citizenCompensationGroups: "Arbeidsdus\u00f8r",
	mainMenu_finance_exportTransactionGroups: "Eksportlogg - Oppkrevinger",
	mainMenu_finance_payoutExportLogs: "Eksportlogg - Utbetalinger",
	mainMenu_effectuation: "Medisinh\u00e5ndtering",
	mainMenu_effectuation_internalEffectuations: "Utleveringsliste",
	mainMenu_effectuation_drugInventoryItems: "Lagerbeholdning",
	mainMenu_effectuation_labelPrint: "Utskrift av etiketter",
	mainMenu_depot: "Depot",
	mainMenu_depot_repairsView: "Til reparasjon",

	patientMenu_overview: "Oversikt",
	patientMenu_citizenPathway: "Brukersaker",
	patientMenu_calendar: "Kalender",
	patientMenu_correspondence: "Korrespondanse",
	patientMenu_correspondence_inbox: "Innboks",
	patientMenu_correspondence_draft: "Utkast",
	patientMenu_correspondence_outbox: "Sendt",
	patientMenu_correspondence_deleted: "Slettet",
	patientMenu_citizenData: "Data",
	patientMenu_medicine: "Medisin",
	patientMenu_medicine_current: "Aktuell medisin",
	patientMenu_medicine_dispensing: "Dispensering",
	patientMenu_medicine_handout: "Medisinliste utlevering",
	patientMenu_medicine_nursing: "Medisinliste sykepleie",
	patientMenu_medicine_dispensation: "PN Medisin",
	patientMenu_medicine_history: "Historikk",
	patientMenu_finance: "\u00d8konomi",
	patientMenu_finance_overview: "Alle tjenester",
	patientMenu_hclMatching: "Utl\u00e5n",
	patientMenu_hclMatching_current: "Aktuelt",
	patientMenu_hclMatching_history: "Historikk",
	patientMenu_eop: "Effekt & Progresjon"
};
